.chart_chainGraph_imgs_main {
  display: flex;
  gap: 2rem;
}
.chart_chainGraph_imgs_inner {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 530px) {
  .chart_chainGraph_imgs_main {
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .chart_chainGraph_imgs_inner {
    height: 200px;
  }
}

@media screen and (max-width: 1020px) {
  .chart_chainGraph_imgs_inner {
    height: 270px;
  }
}
