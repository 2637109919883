.ContactUsPage_main {
  margin: 0 auto;
  max-width: 1140px;
  padding: 3rem 0;
  padding-bottom: 6rem;
}

.ContactUsPage_form {
  display: flex;
  gap: 2.5rem;
  padding: 0 2rem;
  align-items: center;
}

.c_form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
}

.c-txt,
.c-msg-txt {
  padding: 0.4rem;
  font-size: 16px;
  border: 2px solid;
}

.map_inner {
  height: 370px;
  width: 50%;
}

.btn_form_red {
  background: darkorange;
  border: 2px solid darkorange;
  margin-top: 2rem;
  cursor: pointer;
}

.btn_form_red:hover {
  border: 2px solid darkorange;
  color: darkorange;
}

@media screen and (max-width: 768px) {
  .ContactUsPage_form {
    align-items: center;
    flex-direction: column;
  }
  .c_form {
    width: 100%;
  }

  .map_inner {
    width: 100%;
  }
}
