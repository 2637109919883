.AboutPage_main {
  margin: 0 auto;
  max-width: 1140px;
  padding: 3rem 0;
  padding-bottom: 4rem;
  display: flex;
  gap: 4rem;
  flex-direction: column;
}
.ProductRange_inner {
  width: 100%;
}

.about_head {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  text-align: center;
  background: whitesmoke;
}

.about_galaxy_chain_main,
.about_galaxy_conveyor_main {
  background: white;
  color: black;
  padding: 2rem;
  /* border: 3px solid; */
  box-shadow: 14px 8px 16px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.about_intro_para_main {
  line-height: 1.5;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-size: 19px;
}

.About_certificate_main {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 400px;
  color: black;
  box-shadow: 14px 8px 16px 0px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.About_certificate_main:hover {
  transform: scale(1.05);
}

.About_certificate_list_main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* color: azure; */
}
.About_product_range_block_main {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.About_product_range_main {
  /* display: flex;
  flex-direction: column;
  gap: 0.5rem; */

  background: white;
  padding: 1rem;
  border-radius: 10px;
  color: black;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.About_certificate_list_h3,
.About_product_range_list_h3 {
  letter-spacing: 1px;
}
.About_product_range_list_h3 {
  color: var(--color-blue);
}

.About_part2_main {
  display: flex;
  margin: 3rem;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 4rem 0;
}

.About_part2_inner_1 {
  border-radius: 20px;
  width: 45%;
  display: flex;
}

.About_part2_inner_2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.about_para_main {
  display: flex;
  padding: 2rem;
  border: 2px solid;
  margin: 2rem;
  gap: 2rem;
}

.about_para_main_img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.about_para_main_part1 {
  width: 40%;
}
.about_para_main_part2 {
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 1rem;
}

.Certificate_img_top {
  /* margin: 2rem; */
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
.Certificate_img_main {
  height: 400px;
}
@media screen and (max-width: 1050px) {
  .About_part2_inner_1 {
    height: 300px;
    width: 100%;
  }
  .About_part2_inner_2 {
    width: 100%;
  }
}
@media screen and (max-width: 1210px) {
  .about_galaxy_chain_main,
  .about_galaxy_conveyor_main {
    margin: 0 2rem;
  }
  .About_certificate_main {
    width: 100%;
    margin: 0 2rem;
  }

  .about_para_main_part1 {
    width: 100%;
    height: 400px;
  }

  .about_para_main {
    flex-direction: column;
  }
  .about_para_main_part2 {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .About_part2_main {
    margin: 2rem;
    gap: 1.5rem;
  }
  .About_part2_inner_1 {
    border-radius: 10px;
    height: 200px;
  }
  .About_part2_inner_1 img {
    border-radius: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .about_para_main_part1 {
    height: 100%;
  }

  .about_para_main {
    padding: 1.5rem;
    margin: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  .about_head {
    font-size: small;
  }
}
