.home_slider_card_main {
  width: 375px !important;
  height: 220px;
  position: relative;
}

.home_slider_img {
  width: 100%;
  height: 100%;
}

.home_slider_con {
  padding: 2rem 0;
  padding-bottom: 6rem;
  max-width: 1280px;
  margin: 0 auto;
}

.home_slider_img_inner_main {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(22, 42, 131);
  background: linear-gradient(
    0deg,
    rgba(22, 42, 131, 1) 35%,
    rgba(22, 42, 131, 0.49343487394957986) 100%
  );
}

.home_slider_img_txt {
  color: var(--color-white);
  font-weight: 600;
  font-size: 23px;
  background: #203186;
}

@media screen and (max-width: 1080px) {
  .home_slider_card_main {
    width: 300px !important;
  }
}

@media screen and (max-width: 920px) {
  .home_slider_card_main {
    width: 100% !important;
  }
}

@media screen and (max-width: 650px) {
  .home_slider_card_main {
    width: 100% !important;
  }
}

/*  */

/* .Industries_We_Empower_main {
  margin: 0 auto;
  max-width: 1140px;
  padding: 3rem 0;
}
.industry_icon_main {
  height: 60px;
  width: 60px;
}
.industry_icon_inner {
  height: 100%;
  width: 100%;
}

.industry_box_top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
   justify-items: center;
}

.industry_box_main {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  border: 3px solid;
  border-radius: 12px;
  width: 100%;
   height: 100px;
  justify-content: center;
  text-align: center;
  transition: transform 0.3s ease;
}

.industry_box_main:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1175px) {
  .industry_box_top {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .industry_box_top {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 555px) {
  .industry_box_main {
    flex-direction: column;
    gap: 0.4rem;
  }
  .industry_icon_main {
    height: 45px;
    width: 45px;
  }
  .industry_box_top {
    margin: 1rem;
  }
  .industry_box_top {
    gap: 1rem;
  }
} */
