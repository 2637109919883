.CategorySliderHome_main {
  margin: 0 auto;
  max-width: 1140px;
  padding: 3rem 2rem;
}

.category_box_main {
  background: #ca3433;
  color: white;
  border-radius: 15px;
  padding: 1rem;
  /* display: flex !important; */
  flex-direction: column;
  gap: 1rem;
}
.category_box_main2 {
  background: #e26310;
  color: white;
  border-radius: 15px;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
}
.category_img_main {
  height: 200px;
  /* width: 300px; */
}

.category_img_inner {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.slick-slider .slick-track {
  display: flex;
  gap: 1rem;
}

.See_More_btn {
  border: 2px solid;
  color: white;
  border-radius: 10px;
  background: none;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  transition: transform 0.9s ease;
  margin: 1rem 0;
}

.See_More_btn:hover {
  transform: scale(0.8);
  background: white;
  color: black;
}

.cat_card_main_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem !important;
  flex-wrap: wrap;
  padding-top: 3rem;
}
.cat_card_main {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.cat_card_main:hover {
  transform: translateY(-10px);
}

.cat_arrow_main {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--color-red);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.cat_desc_part {
  background-color: var(--color_orange_shade2);
  width: 100%;
  padding: 3rem 2rem 2rem 2rem;
  text-align: center;
  height: 150px;
  transform: translateY(-20px);
}

.cat_desc {
  font-size: 24px;
  font-weight: 800;
  color: var(--color-white);
}

@media screen and (max-width: 1250px) {
  .cat_card_main_flex {
    overflow: scroll;
    flex-wrap: nowrap;
    gap: 2px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 750px) {
  .cat_card_main_flex {
    justify-content: flex-start;
  }
}
